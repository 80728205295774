@import 'chunkfive-font-face.css';
@import 'helvetica-font-face.css';
@import-normalize;

html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
}

body {
	margin: 0;
	color: var(--text-color-dark);
	font: 300 100%/1.5 var(--font-fam-body);
	background-color: var(--background-color-light);
}

h1,
h2,
h3 {
	color: var(--color-brand);
	font-family: var(--font-fam-heading);
}

::selection {
	background: var(--action-background-l1);
}

a {
	transition: color 0.3s ease;
	color: var(--color-brand);
	
	@media (any-hover) {
	&:hover {
		color: var(--color-brand-secondary);
	}
	}
}

img {
	border: none;
}

ul,
ol {
	padding: 0;
	margin: 0;
}

.cf {
	&:before,
	&:after {
	  content: "";
	  display: table;
	}
 
	&:after {
	  clear: both;
	}
}

.sr-only {
	position: absolute;
	clip: rect(0 0 0 0);
	overflow: hidden;
	height: 1px;
	width: 1px;
	padding: 0;
	border: 0;
	margin: -1px;
}

a.navigation-button {
	display: inline-block;
	font-size: 0.875rem;
	font-weight: bold;
	text-align: center;
	text-decoration: none;
	color: var(--text-color-light);
	background-color: var(--background-color-dark);
	padding: var(--space-l1) var(--space-l2);

	&:hover {
		color: var(--text-color-light2);
	}
}

.container {
	max-width: var(--content-max-width);
	padding-left: var(--space-l1);
	padding-right: var(--space-l1);
	margin-left: auto;
	margin-right: auto;
}

@media (inverted-colors) {
	img {
		filter: invert(100%);
	}
}