@font-face {
	font-family: 'Helvetica Now';
	src: url('/assets/fonts/Helvetica-Light.woff2') format('woff2'),
		 url('/assets/fonts/Helvetica-Light.woff') format('woff');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Helvetica Now';
	src: url('/assets/fonts/Helvetica-Regular.woff2') format('woff2'),
		 url('/assets/fonts/Helvetica-Regular.woff') format('woff');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}