:root {
    --header-height: 7rem;
}
@custom-media --header-expanded-width (min-width: 82.5em);

.site-header {
    z-index: 1;
    position: fixed;
    top: 0;
    width: 100%;
}

.site-header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: var(--section-max-width);
    padding: var(--space-l1);
    padding-top: calc(env(safe-area-inset-top) + var(--space-l1));
    height: var(--header-height);
    background-color: var(--background-color-light);
    border-bottom: 3px solid var(--color-brand);
    margin: 0 auto;

    @media (--header-expanded-width) {
        padding: 2rem 3rem;
    }
}

.site-branding {
    svg {
        height: 100%;
        width: auto;
    }
}

.site-navigation {
    @media (--header-expanded-width) {
        flex: 1 0 auto;
        margin-left: var(--space-l1);
    }
}

.site-navigation-menu-toggle {
    transition: background-color 0.3s ease;
    height: 2rem;
    width: 2rem;
    background: none;
    padding: 0;
    border: none;
    margin: 0;

    @media (--header-expanded-width) {
        display: none;
    }

    &:hover {
        background-color: var(--background-color-light2);
    }

    &[aria-expanded="false"] {
        svg.close {
            display: none;
        }
    }

    &[aria-expanded="true"] {
        svg.open {
            display: none;
        }
    }
}

.site-navigation-menu {
    transition: max-height 0.3s ease;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: var(--header-height);
    right: 0;
    width: 80%;
    background-color: var(--background-color-light);
    list-style: none;
    overflow: hidden;

    &[aria-expanded="false"] {
        transition-timing-function: ease-in;
        max-height: 0;
    }
    
    &[aria-expanded="true"] {
        transition-timing-function: ease-out;
        max-height: 100vh;
    }

    li a[aria-current] {
        color: var(--color-brand);
    }

    li.navigation-item-button a[aria-current] {
        background-color: var(--background-color-dark);
        color: var(--text-color-light);
    }

    a {
        display: block;
        font-size: 1.25rem;
        font-weight: bold;
        color: var(--text-color-dark);
        text-decoration: none;
        padding: var(--space-l2);
        border-bottom: 1px solid var(--text-color-dark);
    }

    @media (--header-expanded-width) {
        flex-direction: row;
        justify-content: flex-end;
        position: static;
        width: 100%;
        background-color: transparent;
        overflow: visible;

        &[aria-expanded="false"] {
            max-height: none;
        }

        li + li {
            margin-left: var(--space-l2);
        }

        a {
            display: inline;
            font-size: 1rem;
            padding: 0;
            border-bottom: none;
        }
    }
}

@media (--header-expanded-width) {
.navigation-item-button {
    a {
        padding: var(--space-l1);
        border: 2px solid var(--color-brand);
    }
}
}

main {
	margin-top: var(--header-height);
}