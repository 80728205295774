@custom-media --width-l1 (min-width: 22.5em);
@custom-media --width-l2 (min-width: 35em);
@custom-media --width-l3 (min-width: 50em);
@custom-media --width-l4 (min-width: 65em);

:root {
	/* Colors */
	--color-brand: #0077BE;
	--color-brand-secondary: #5BB2E5;
	--color-brand-tertiary: #0A347A;
	--text-color-dark: #4A4A4A;
	--text-color-light: white;
	--text-color-light2: #C7E8F9;
	--background-color-light: white;
	--background-color-light2: #EEEEEE;
	--background-color-light3: #C7E8F9;
	--background-color-dark: #0077BE;
	--background-color-dark2: #848588;
	--background-color-dark3: #5BB2E5;
	--action-background-l1: rgba(0, 119, 190, 0.3);
	
	/* Typefaces */
	--font-fam-body: 'Helvetica Now', 'Helvetica Neue', 'Helvetica LT Std', 'Helvetica', system-ui, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen-Sans', 'Ubuntu', 'Cantarell', sans-serif;
	--font-fam-heading: 'Chunk Five', system-ui, -apple-system, 'Segoe UI', 'Roboto', 'Oxygen-Sans', 'Ubuntu', 'Cantarell', 'Helvetica Neue', sans-serif;
	
	/* Spacing */
	--space-l1: 0.625rem;
	--space-l2: 1.25rem;
	--space-l3: 2.5rem;
	--space-l4: 5rem;
	
	--content-max-width: 80rem;
	--section-max-width: 90rem;
}
