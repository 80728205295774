.site-footer {
    color: var(--text-color-light);
    background-color: var(--color-brand);
    max-width: var(--section-max-width);
    padding: var(--space-l3) 0;
    padding-bottom: env(safe-area-inset-bottom) + var(--space-l3));
    margin: 0 auto;

    @media (--width-l2) {
        padding: var(--space-l4) 0;
        padding-bottom: env(safe-area-inset-bottom) + var(--space-l4));
    }

    .navigation-button {
        border: 1px solid var(--background-color-light);
        text-transform: uppercase;
        margin: 0 auto;
    }

    &.home {
        .site-footer-statistics {
            display: flex;
            margin-bottom: var(--space-l3);
        }

        .site-footer-statistics-item {
            display: block;
            position: relative;

            & + .site-footer-statistics-item {
                margin-left: 1.5rem;

                &:nth-child(3) {
                    margin-left: 5.5rem;
                }
    
                @media (--width-l3) {
                    margin-left: 2.5rem;

                    &:nth-child(3) {
                        margin-left: 6.5rem;
                    }
                }
            }

            .site-footer-statistics-description {
                text-transform: uppercase;
            }
            .site-footer-statistics-number {
                position: relative;
                font-family: var(--font-fam-heading);
                font-size: 2rem;
                line-height: 1;
            }

            .site-footer-statistics-title {
                display: block;
                font-family: var(--font-fam-body);
                font-size: 0.9rem;
            }

            .site-footer-statistics-number .site-footer-statistics-title {
                display: inline;
                position: absolute;
                left: 0;
                top: 100%;
            }
        }

        @media (--width-l3) {
            .container {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .site-footer-statistics {
                flex: 1 0 auto;
                margin-bottom: 0;
                margin-right: var(--space-l2);
            }

            .site-footer-join {
                flex: 1 0 auto;
            }
        }
    }

    &.talent {
        h2 {
            color: var(--text-color-light);
        }

        .site-footer-text p {
            max-width: 30rem;
        }

        .site-footer-join {
            text-align: center;
        }
        
        @media (--width-l2) {
            .container {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .site-footer-text p {
                margin-right: var(--space-l3);
            }

            .site-footer-join {
                flex: 1 0 auto;
            }
        }
    }
}